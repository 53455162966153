<template>
  <v-row align="center" justify="center" class="pa-4">
    <v-col>
      <h1 style="font-weight: 800; color: #FF0000">แก้ไขข้อมูลบริษัท (EPP)</h1>
    </v-col>
    <v-card class="pa-8" width="100%">
      <v-form ref="createForm" v-model="checkValidate" :lazy-validation="lazy">
        <v-row>
          <v-col cols="12" md="3" sm="3"
            ><span class="pt-2">รหัสบริษัท</span>
            <span style="color: red">*</span>
          </v-col>
          <v-col cols="6" md="7" sm="7">
            <v-text-field
              v-model="companyCode"
              label="รหัสบริษัท"
              solo
              dense
              :rules="rules.required"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="3" sm="3"
            ><span class="pt-2">ชื่อบริษัท</span>
            <span style="color: red">*</span></v-col
          >
          <v-col cols="6" md="7" sm="7">
            <v-text-field
              v-model="companyName"
              label="ชื่อบริษัท"
              solo
              dense
              :rules="rules.required"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="3" sm="3"
            ><span class="pt-2">อีเมลบริษัท</span>
            <span style="color: red">*</span></v-col
          >
          <v-col cols="6" md="7" sm="7">
            <v-text-field
              v-model="suffixEmail"
              placeholder="example@mail.com"
              solo
              dense
              :rules="rules.required"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="3" sm="3"
            ><span class="pt-2">รายละเอียด</span>
            <span style="color: red">*</span></v-col
          >
          <v-col cols="6" md="7" sm="7">
            <v-text-field
              v-model="description"
              label="รายละเอียด"
              solo
              dense
              :rules="rules.required"
            ></v-text-field>
          </v-col>
        </v-row>
         <v-row>
          <v-col cols="12" md="3" sm="3"
            ><span class="pt-2">หมายเหตุ</span>
            <span style="color: red">*</span></v-col
          >
          <v-col cols="6" md="7" sm="7">
            <v-textarea
              v-model="companyNote"
              placeholder="หมายเหตุ"
              solo
              dense
              :rules="rules.required"
            ></v-textarea>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="3" sm="3" class="pt-8"
            ><span>ตรวจสอบจาก Email บริษัท</span>
          </v-col>
          <v-col cols="6" md="7" sm="7">
            <v-checkbox v-model="checkEmailFlag"></v-checkbox>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="3" sm="3" class="pt-8"
            ><span>ตรวจสอบจากเบอร์มือถือที่ให้ไว้</span>
          </v-col>
          <v-col cols="6" md="7" sm="7">
            <v-checkbox v-model="checkTelFlag"></v-checkbox>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="3" sm="3" class="pt-8"
            ><span>ตรวจสอบจากชื่อพนักงาน</span>
          </v-col>
          <v-col cols="6" md="7" sm="7">
            <v-checkbox v-model="checkNameFlag"></v-checkbox>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="3" sm="3" class="pt-8"
            ><span>ตรวจสอบจากรหัสพนักงาน</span>
          </v-col>
          <v-col cols="6" md="7" sm="7">
            <v-checkbox v-model="checkEmployeeCodeFlag"></v-checkbox>
          </v-col>
        </v-row>
        <v-col cols="12" md="12" sm="6" xs="12">
          <v-card outlined>
            <v-row no-gutters align="center" justify="center">
              <v-col cols="11" class="mt-5 ma-10" id="step-3">
                <span>รูปโลโก้หลัก</span>
                <span style="color: red">*</span>
                <v-row align="center" justify="center">
                  <v-img
                    v-model="picture_1"
                    @click="changePic1()"
                    src="@/assets/upload.png"
                    class="mr-3"
                    max-width="50"
                  >
                  </v-img>
                  <span>เลือกรูปภาพ</span>
                </v-row>
                <v-col
                  cols="3"
                  md="3"
                  class="pl-5 pr-5 row fill-height align-center sortable-list"
                >
                  <v-card dense light class="pa-1" v-if="showImage">
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-icon small light @click="RemoveImage(index, item)"
                        >mdi-close</v-icon
                      >
                    </v-card-actions>

                    <v-img
                      :src="showImage"
                      width="200px"
                      aspect-ratio="1.8"
                      contain
                    ></v-img>
                  </v-card>
                </v-col>
                <input
                  type="file"
                  ref="image"
                  id="picTure1"
                  accept="image/png"
                  @change="showPicture1($event)"
                  style="display: none"
                  hidden
              /></v-col>
            </v-row>
          </v-card>
        </v-col>
        <v-col cols="12" md="12" sm="6" xs="12">
          <v-card outlined>
            <v-row no-gutters align="center" justify="center">
              <v-col cols="11" class="mt-5 ma-10" id="step-3">
                <span>รูปแบนเนอร์บริษัท</span>
                <span style="color: red">*</span>
                <v-row align="center" justify="center">
                  <v-img
                    v-model="picture_2"
                    @click="changePic2()"
                    src="@/assets/upload.png"
                    class="mr-3"
                    max-width="50"
                  >
                  </v-img>
                  <span>เลือกรูปภาพ</span>
                </v-row>
                <v-col
                  cols="3"
                  md="3"
                  class="pl-5 pr-5 row fill-height align-center sortable-list"
                >
                  <v-card dense light class="pa-1" v-if="showImage2">
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-icon small light @click="RemoveImage(index, item)"
                        >mdi-close</v-icon
                      >
                    </v-card-actions>

                    <v-img
                      :src="showImage2"
                      width="200px"
                      aspect-ratio="1.8"
                      contain
                    ></v-img>
                  </v-card>
                </v-col>
                <input
                  type="file"
                  ref="image"
                  id="picTure2"
                  accept="image/png"
                  @change="showPicture2($event)"
                  style="display: none"
                  hidden
              /></v-col>
            </v-row>
          </v-card>
        </v-col>
        <!-- <v-row>
          <v-col cols="12" md="3" sm="3"><p class="pt-4">สถานะ*</p></v-col>
          <v-col cols="12" md="3" sm="3">
            <v-switch v-model="brandStatus" label="ใช้งาน"></v-switch>
          </v-col>
        </v-row> -->
        <v-row align="center" justify="end">
          <v-btn @click="cancel()" class="mr-4">กลับ</v-btn>
          <v-btn @click="submit()" color="primary">บันทึก</v-btn>
        </v-row>
      </v-form>
    </v-card>
  </v-row>
</template>
<script>
import { Decode } from "@/services";
export default {
  data() {
    return {
      // รหัสบริษัท
      // ชื่อบริษัท
      // Email บริษัท
      // รายละเอียด
      // รูปภาพโลโก้
      // // แบรนด์สินค้า
      checkEmailFlag: false,
      checkTelFlag: false,
      checkNameFlag: false,
      checkEmployeeCodeFlag: false,
      companyCode: "",
      companyName: "",
      suffixEmail: "",
      description: "",
      companyNote:"",
      companyImg: "",
      companyBannerImg: "",
      brand: [],
      pic1: "",
      picture_1: "",
      showImage: "",
      imageName1: "",
      pic2: "",
      picture_2: "",
      showImage2: "",
      imageName2: "",
      activeFlag: true,

      rules: {
        required: [(v) => !!v || "กรุณากรอกข้อมูล"],
        emailRules: [
          (v) => !!v || "E-mail is required",
          (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
        ],
        phone: [
          (v) =>
            (v.length <= 10 && v.length >= 9) ||
            "หมายเลขโทรศัพท์ต้องมี 9-10 ตัวเท่านั้น",
          (v) => !!v || "กรุณากรอกข้อมูลให้ครบถ้วน",
        ],
        maxAge: [
          (v) => v.length <= 2 || "ระบุอายุระหว่าง 12-60ปี เท่านั้น",
          (v) => !!v || "This field is required",
        ],
      },
      checkValidate: true,
      lazy: false,
      user: "",
    };
  },
  created() {
    var Companydata = JSON.parse(
      Decode.decode(localStorage.getItem("Companydata"))
    );
    console.log("Companydata", Companydata);
    // companyCode: "",
    //   companyName: "",
    //   suffixEmail: "",
    //   description: "",
    //   companyImg: "",
    //   brand: [],
    //   pic1: "",
    //   picture_1: "",
    //   showImage: "",
    //   imageName1: "",
    //   activeFlag: true,
    this.id = Companydata.id;
    this.checkEmailFlag = Companydata.checkEmailFlag;
    this.checkTelFlag = Companydata.checkTelFlag;
    this.checkNameFlag = Companydata.checkNameFlag;
    this.checkEmployeeCodeFlag = Companydata.checkEmployeeCodeFlag;
    this.companyCode = Companydata.companyCode;
    this.companyName = Companydata.companyName;
    this.suffixEmail = Companydata.suffixEmail;
    this.description = Companydata.description;
    this.companyNote = Companydata.companyNote;
    this.showImage = Companydata.companyImg;
    this.pic1 = Companydata.companyImg;
    this.picture_1 = Companydata.companyImg;
    this.showImage2 = Companydata.companyBannerImg;
    this.pic2 = Companydata.companyBannerImg;
    this.picture_2 = Companydata.companyBannerImg;
    this.activeFlag = Companydata.activeFlag;

    // id: "",
    //   companyNo: "",
    //   companyName: "",
    //   companyEmail: "",
    //   companyDescription: "",
    //   companyLogo: "",
    //   brand: [],
  },
  methods: {
    showPicture1(e) {
      // console.log('index',index);
      const files = e.target.files;
      if (files[0] !== undefined) {
        this.picture_1 = files[0].name;
        const element = files[0];
        const reader = new FileReader();
        reader.readAsDataURL(element);
        reader.onload = () => {
          this.pic1 = reader.result;
          this.showImage = URL.createObjectURL(element);
          console.log(this.pic1);
        };
      }
    },
    changePic1() {
      document.getElementById("picTure1").click();
    },
    showPicture2(e) {
      // console.log('index',index);
      const files = e.target.files;
      if (files[0] !== undefined) {
        this.imageName2 = files[0].name;
        const element = files[0];
        const reader = new FileReader();
        reader.readAsDataURL(element);
        reader.onload = () => {
          this.pic2 = reader.result;
          this.showImage2 = URL.createObjectURL(element);
          // console.log(this.pic1);
        };
      }
    },
    changePic2() {
      document.getElementById("picTure2").click();
    },
    onPickFile() {
      document.getElementById("file_input").click();
    },
    cancel() {
      this.$router.push("ManageCompany");
    },
    async submit() {
      if (this.$refs.createForm.validate(true)) {
        var user = JSON.parse(
          Decode.decode(localStorage.getItem("userHealthAdmin"))
        );
        const auth = {
          headers: { Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MjcsImVtYWlsIjoiaXR0aXd1dC53b25nM0BnbWFpbC5jb20iLCJwYXNzd29yZCI6IiQyYiQxMCRqU0UvY3RsSGhmTmJYemV5QXAvUHhPc3NOSS9HNG5NTHNsNlNBanFySzlVYzFMQmhDazh1UyIsImZpcnN0TmFtZSI6InNkZiIsImxhc3ROYW1lIjoic2RmIiwidGVsQ29kZSI6Iis2NiIsInRlbE51bWJlciI6IjIzNDU0NSIsImxpbmUiOiJkZnMiLCJnZW5kZXIiOiIiLCJhZ2UiOjEyLCJsaWNlbnNlTm8iOiIiLCJzY2hvb2wiOiIiLCJleHBlcmllbmNlIjoiZnNkZiIsImFsbGVyZ3kiOiJzZGYiLCJtaW5GZWUiOjEwLCJtYXhGZWUiOjIwLCJwcm92aW5jZSI6IuC4quC4oeC4uOC4l-C4o-C4m-C4o-C4suC4geC4suC4oyIsInVzZXJSZXF1ZXN0Ijoic2RmIiwiaWRDYXJkSW1nIjpudWxsLCJlZHVjYXRpb25JbWciOm51bGwsIndvcmtpbmdBcmVhIjoiamtqIiwibGF0IjpudWxsLCJsbmciOm51bGwsInNjaGVkdWxlIjoiWzIsM10iLCJyb2xlIjoiVVNFUiIsImlzUHJvIjpmYWxzZSwiaXNQdWJsaWMiOmZhbHNlLCJhY3RpdmVGbGFnIjp0cnVlLCJjcmVhdGVkQXQiOiIyMDIxLTExLTExVDA1OjQ3OjM4LjAwMFoiLCJ1cGRhdGVkQXQiOiIyMDIxLTExLTExVDExOjUxOjM2LjAwMFoiLCJqb2JJZCI6NCwiZWR1Y2F0aW9uSWQiOjEsImxpY2Vuc2VJZCI6MSwic2VydmljZVR5cGVJZCI6MSwiZmVlQ3VycmVuY3lJZCI6MSwiZmVlRnJlcXVlbmN5SWQiOjIsImlhdCI6MTYzNjkwODA0Mn0.4ctskWtU_WO5pDZMDa6M8vxLFCJZPaRCNxhNMiwl0-E` },
        };
        const data = {
          id: this.id,
          checkEmailFlag: this.checkEmailFlag,
          checkTelFlag: this.checkTelFlag,
          checkNameFlag: this.checkNameFlag,
          checkEmployeeCodeFlag: this.checkEmployeeCodeFlag,
          companyCode: this.companyCode,
          companyName: this.companyName,
          suffixEmail: this.suffixEmail,
          description: this.description,
          companyNote: this.companyNote,
          companyImg: this.pic1,
          companyBannerImg: this.pic2,
          activeFlag: this.activeFlag,
        };
        const response = await this.axios.put(
          `${process.env.VUE_APP_API}/companies/` + this.id,
          data,
          auth
        );
        console.log("createCompany", response);
        console.log(response.data.response_status);
        if (response.data.response_status == "SUCCESS") {
          this.$swal.fire({
            icon: "success",
            text: `แก้ไขข้อมูลบริษัทสำเร็จ`,
            showConfirmButton: false,
            timer: 1500,
          });
          this.$router.push("ManageCompany");
        } else {
          this.$swal.fire({
            icon: "error",
            text: `แก้ไขข้อมูลบริษัทไม่สำเร็จ`,
            showConfirmButton: false,
            timer: 1500,
          });
        }
      }
    },
  },
};
</script>